<template>
  <div class="automatic-payments">
    <div class="card-payments">
      <div class="card-header-ds">
        <div class="card-title-ds">Automatic Payments</div>
        <div
          v-if="showFilterPrincipal"
          class="principal-filter d-flex justify-content-between align-items-center h-full px-1"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="cursor-pointer mr-1"
            :style="isDarkSkin ? 'color: #fff' : '#000'"
            @click="prev"
          />
          <span
            class="font-size:15px"
            :style="isDarkSkin ? 'color: #fff' : '#000'"
          >
            {{ months[dataMonth - 1] }} {{ dataYear }}
          </span>
          <feather-icon
            icon="ChevronRightIcon"
            size="20"
            class="cursor-pointer ml-1"
            :style="isDarkSkin ? 'color: #fff' : '#000'"
            @click="next"
          />
        </div>
      </div>
      <div class="card-body-ds">
        <div class="grid-container">
          <div class="grid-item bg-primary">Days</div>
          <div class="grid-item bg-white">
            <div class="item-element">5th</div>
            <div class="item-element">10th</div>
            <div class="item-element">15th</div>
            <div class="item-element">20th</div>
            <div class="item-element">25th</div>
            <div class="item-element">30th</div>
          </div>
          <div class="grid-item bg-primary">Total</div>

          <div class="grid-item bg-primary">Success</div>
          <div class="grid-item bg-white">
            <template v-for="(item, index) in autoPaymentPay">
              <template v-for="(pay, key) in item">
                <div
                  v-if="key.startsWith('pay_')"
                  class="item-element text-primary font-weight-bolder"
                  :key="`${index}-${key}`"
                >
                  <b-spinner v-if="item.state === 0" small variant="success" />
                  <template v-else>
                    <div v-if="pay" :class="{ 'cursor-pointer': pay > 0 }">
                      <span
                        @click="
                          pay > 0 &&
                            ModalClientAutomatic(item, Number(key.slice(4)), 1)
                        "
                      >
                        {{ pay }}
                      </span>
                    </div>
                    <div v-else>-</div>
                  </template>
                </div>
              </template>
            </template>
          </div>
          <div class="grid-item grid-item--total bg-white">
            <b-spinner
              v-if="autoPaymentPay[0].state === 0"
              small
              variant="success"
            />
            <template v-else>
              <div
                class="text-primary font-weight-bolder"
                :class="{ 'cursor-pointer': totalAutoPaymentsPay > 0 }"
                @click="
                  totalAutoPaymentsPay > 0 &&
                    ModalClientAutomatic(autoPaymentPay[0], null, 1)
                "
              >
                {{ totalAutoPaymentsPay || 0 }}
              </div>
            </template>

            <b-spinner
              v-if="autoPaymentWo[0].state === 0"
              small
              variant="danger"
            />
            <template v-else>
              <div
                class="text-danger"
                :class="{ 'cursor-pointer': totalAutoPaymentsWo > 0 }"
                @click="
                  totalAutoPaymentsWo > 0 &&
                    ModalClientAutomatic(autoPaymentWo[0], null, 0)
                "
              >
                {{ totalAutoPaymentsWo || 0 }}
                <span
                  class="text-success clickable"
                  v-if="totalAutoPaymentsRe > 0"
                  @click.stop="
                    ModalClientAutomatic(autoPaymentWo[0], null, 0, true)
                  "
                >
                  ({{ totalAutoPaymentsRe || 0 }})
                </span>
              </div>
            </template>
          </div>

          <div class="grid-item bg-primary">Declined</div>
          <div class="grid-item bg-white">
            <template v-for="(item, index) in autoPaymentWo">
              <template v-for="(wo, key) in item">
                <div
                  v-if="key.startsWith('wo_')"
                  class="item-element text-danger"
                  :key="`${index}-${key}`"
                >
                  <b-spinner v-if="item.state === 0" small variant="danger" />
                  <template v-else>
                    <div class="d-flex justify-content-center gap-5">
                      <div v-if="wo" :class="{ 'cursor-pointer': wo > 0 }">
                        <span
                          @click="
                            wo > 0 &&
                              ModalClientAutomatic(
                                item,
                                Number(key.slice(3)),
                                0
                              )
                          "
                        >
                          {{ wo }}
                        </span>
                      </div>
                      <div v-else>-</div>
                      <span
                        class="text-success clickable"
                        v-if="
                          autoPaymentRe[index][replaceName(key, 'wo_', 're_')]
                        "
                        @click.stop="
                          ModalClientAutomatic(
                            item,
                            Number(key.slice(3)),
                            0,
                            true
                          )
                        "
                      >
                        ({{
                          autoPaymentRe[index][replaceName(key, "wo_", "re_")]
                        }})
                      </span>
                    </div>
                  </template>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>

    <clients-auto-modal
      v-if="showModalClientsAuto"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-programs-id="programId"
      :send-programs="arrPrograms"
      :day-payment="dayPayment"
      :is-paid="isPaid"
      :is-recovered="isRecovered"
      @close="showModalClientsAuto = false"
    />
  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import ClientsAutoModal from "@/views/ceo/dashboard/modals/ClientsAutoModal.vue";
export default {
  name: "AutomaticClientPayments",
  components: {
    ClientsAutoModal,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
    programId: {
      type: Number,
      default: null,
      required: false,
    },
    showFilterPrincipal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      imgServices: require("@/assets/images/icons/dashboard-ceo/services.svg"),
      autoPayments: [
        {
          program_id: null,
          state: 0,
          pay_5: 0,
          wo_5: 0,
          pay_10: 0,
          wo_10: 0,
          pay_15: 0,
          wo_15: 0,
          pay_20: 0,
          wo_20: 0,
          pay_25: 0,
          wo_25: 0,
          pay_30: 0,
          wo_30: 0,
          total_pay: 0,
          total_wo: 0,
        },
      ],
      showModalClientsAuto: false,
      dayPayment: null,
      isPaid: null,
      arrPrograms: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataMonth: null,
      dataYear: null,
      today: new Date(),
      isRecovered: false,
    };
  },
  computed: {
    autoPaymentPay() {
      return this.autoPayments.map((item) => {
        return {
          program_id: item.program_id,
          state: item.state,
          pay_5: item.pay_5,
          pay_10: item.pay_10,
          pay_15: item.pay_15,
          pay_20: item.pay_20,
          pay_25: item.pay_25,
          pay_30: item.pay_30,
        };
      });
    },
    autoPaymentWo() {
      return this.autoPayments.map((item) => {
        return {
          program_id: item.program_id,
          state: item.state,
          wo_5: item.wo_5,
          wo_10: item.wo_10,
          wo_15: item.wo_15,
          wo_20: item.wo_20,
          wo_25: item.wo_25,
          wo_30: item.wo_30,
        };
      });
    },
    autoPaymentRe() {
      return this.autoPayments.map((item) => {
        return {
          program_id: item.program_id,
          state: item.state,
          re_5: item.re_5,
          re_10: item.re_10,
          re_15: item.re_15,
          re_20: item.re_20,
          re_25: item.re_25,
          re_30: item.re_30,
        };
      });
    },
    totalAutoPaymentsPay() {
      return this.autoPayments[0].total_pay;
    },
    totalAutoPaymentsWo() {
      return this.autoPayments[0].total_wo;
    },
    totalAutoPaymentsRe() {
      return this.autoPayments[0].total_re;
    },
  },
  async created() {
    this.dataMonth = this.selectMonth;
    this.dataYear = this.selectYear;

    if (this.showFilterPrincipal) {
      this.dataMonth = this.today.getMonth() + 1;
      this.dataYear = this.today.getFullYear();
    }

    await this.getAutomaticPayments();
  },
  mounted() {},
  methods: {
    replaceName(value, before, after) {
      return value.replace(before, after);
    },
    async getAutomaticPayments() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        this.autoPayments.map((item) => {
          item.state = 0;
        });
        let { data } = await DashboardServive.getClientAutomaticPayments(
          params
        );

        data = Object.entries(data)
          .map(([key, value]) => ({ program_id: key, state: 1, ...value }))
          .filter((item) => parseInt(item.program_id) === this.programId);

        this.autoPayments = data;
      } catch (error) {
        console.error(error);
      }
    },
    ModalClientAutomatic(item, day, isPaid, isRecovered = false) {
      this.programId = parseInt(item.program_id);
      this.dayPayment = day;
      this.isPaid = isPaid;
      this.showModalClientsAuto = true;
      this.isRecovered = isRecovered;
    },
    async next() {
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.getAutomaticPayments();
    },
    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.getAutomaticPayments();
    },
  },
};
</script>
<style lang="scss">
.automatic-payments {
  .card-payments {
    background: #ffffff;
    padding: 2rem;
    border-radius: 0.3rem;
    font-family: "montserrat";
    height: 100%;
    .card-header-ds {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #3d3d3d;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 0.4rem;
      .card-title-ds {
        font-size: 1.3rem;
        font-weight: 600;
      }
    }
    .card-body-ds {
      padding-top: 1rem;
      .card-body-title {
        display: flex;
        justify-content: center;
        font-size: 1rem;
        align-items: center;
        font-weight: bold;
        padding: 0.8rem 0;
        border-radius: 1rem 1rem 0 0;
        color: #ffffff;
      }
      .grid-container {
        display: grid;
        grid-template-columns: 7rem 1fr 7rem;
        gap: 0.3rem;
        padding-top: 0.3rem;
        .grid-item {
          border-radius: 1rem;
          padding: 1.3rem;
          text-align: center;
        }
        .grid-item.bg-white {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.3rem;
          color: #606060;
          font-size: 1rem;
          font-weight: 400;
          .item-element {
            width: 16.6666666667%;
          }
        }
        .grid-item--total {
          grid-row: 2 /4;
          grid-column: 3;
          flex-direction: column;
          gap: 2.4rem !important;
          .text-success,
          .text-danger {
            font-weight: bold !important;
          }
        }
      }
    }
    .bg-primary {
      background-color: #3a72ea !important;
      color: #ffffff;
      font-weight: bold;
      font-size: 1rem;
    }
    .bg-white {
      background-color: #ffffff !important;
      box-shadow: 0px 0px 7px 0px #0000001a;
    }
    .text-success {
      color: #00b027 !important;
      font-weight: 500 !important;
    }
    .text-danger {
      color: #e70000 !important;
      font-weight: 500;
    }
  }
}

.dark-layout {
  .automatic-payments {
    .card-payments {
      background: #1a1a1a;
      .card-header-ds {
        border-bottom: 1px solid #3d3d3d;
        .card-title-ds {
          color: #ffffff;
        }
      }
      .card-body-ds {
        .grid-container {
          .grid-item.bg-white {
            color: #ffffff;
          }
        }
      }
      .bg-white {
        background-color: #111113 !important;
      }
    }
  }
}
</style>
